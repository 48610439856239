$black: #2A2932;
$blue: #547FC4;
$lightBlue: #69A0F7;
$transition: .1s ease-in-out;

body {
   color: $black;
   font-family: 'Avenir';
}

.section {
   width: 90%;
   max-width: 1250px;
   margin: 0 auto;
}

.banner {
   display: flex;
   margin-top: 200px;
   justify-content: space-between;
   position: relative;
   flex-wrap: wrap;

   &__slogan {
      font-size: 64px;
      width: 700px;
      margin-top: 20px;
      line-height: 68px;
      font-weight: 900;
      z-index: 10;
      position: relative;
   }

   &__illustration {
      right: 0;
   }
}

.leaveReqBtn {
   color: #ffffff;
   padding: 11px 28px;
   border-radius: 30px;
   font-weight: 400;
   cursor: pointer;
   outline: none;
   background: $lightBlue;
   font-size: 14px;
   font-weight: 500;
   display: flex;
   align-items: center;
   transition: $transition;

   &:hover {
         background: $blue;
      }
}

.leaveReqBtn--light {
   @extend .leaveReqBtn;
   background: #fff;
   border: 1px solid $lightBlue;
   color: $lightBlue;
   transition: $transition;

   &:hover {
      background: rgba($color: $lightBlue, $alpha: .1);
   }
}

// what we do 

.section-services {
   margin-top: 150px;
   background: linear-gradient(327.5deg, #F2F4F9 17.13%, rgba(242, 244, 249, 0) 94.1%);
}

.skills {
   margin-top: 50px;
   display: flex;
   flex-flow: row wrap;
}


.skill {
   width: 170px; 
   height: 160px;
   background: #FFFFFF;
   border: 2px solid #fff;
   background: rgba(255, 255, 255, 0.5);
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   padding: 20px;
   margin-right: 60px;
   margin-bottom: 37px;
}
 

// what say about us 

.section-feedback {
   margin: 0 auto;
   margin-top: 140px;
   position: relative;
}

.feedback {
   border-left: 2px solid #B5CFF9;
   padding: 0 24px;
   margin-right: 30px;

   &__slider {
      margin: 50px 0;
      height: 200px;

      .slick-slide div {
         outline: none;
      }
   }
   
   &__arrows {
      top: -85px;
      position: absolute;
      cursor: pointer;
      border-radius: 50%;
      transition: $transition;

      &:hover {
         background: rgba($color: $lightBlue, $alpha: .1);
      }
   }

   &__prevArrow {
      @extend .feedback__arrows;
      left: 350px;
      
   }
   
   &__nextArrow {
      @extend .feedback__arrows;
      left: 395px;
   }

   &__bgIllustration {
      position: absolute;
      left: 0;
      top: 50px;
      width: 100%;
      z-index: -10;
   }
}

// portfolio

.section-portfolio {
   @extend .section;
   margin-top: 100px;
   padding-top: 44px;
}

.portfolio {
   &__menuItem {
      padding: 7px 0px;
      cursor: pointer;
      margin-right: 64px;
      transition: $transition;
      border-bottom: 1px solid transparent;
      font-size: 16px;
      font-weight: 500;

      &:hover {
         color: $lightBlue;
      }
   }

   &__menuItem--active {
      @extend .portfolio__menuItem;
      border-bottom: 1px solid $lightBlue;
      color: $lightBlue;
      pointer-events: none;
   }

   &__projects {
      margin-top: 18px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -10px;
      width: 102%;
   }

   &__projectsColumn {
      margin: 10px;
   }

   &__projectBox {
      background: #F7F7F7;
      border-radius: 6px;
      padding: 25px 32px;
      box-sizing: border-box;
      width: 100%;
      height: 350px;
      cursor: pointer;
      margin-bottom: 20px;
      transition: $transition;
      position: relative;
      overflow: hidden;

      &:hover .portfolio__arrow {
         opacity: 1;
         margin-right: 0;
      }
   }

   &__projectImg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   &__projectDesc {
      padding: 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   &__arrow {
      margin-left: 10px;
      opacity: 0;
      transition: .3s ease-in-out;
      margin-right: 5px;
   }

   &__projectBox--bigger {
      min-height: 436px;
   }
}

// phone dropdown

.categories__dropdownWrapper {
   width: 100%;
   margin: 0 auto;
   margin-top: 20px;
   position: relative;
   font-size: 16px;
   height: min-content;
   position: relative;
}

.categories__dropdown {
   margin-top: 10px;
   width: 100%;
   transition: .3s ease-in-out;
   position: absolute;
   top: 0px;
   right: 0;
   z-index: 0;
   display: block;
   opacity: 0;
}

.categories__dropdown--active {
   margin-top: 0px;
   width: 100%;
   display: flex;
   transition: 0.3s ease-in-out;
   flex-direction: column;
   position: absolute;
   z-index: 10;
   top: 50px;
   right: 0;
   opacity: 1;
   padding: 10px 0;
   border: 1px solid $lightBlue;
   background: #fff;
   border-radius: 6px;
}

.categories__dropdownItem {
   padding: 7px 0px;
   cursor: pointer;
   font-weight: 500;
   transition: $transition;
   cursor: pointer;
   width: 90%;
   margin:  0 auto;
   margin-top: 3px;
   font-size: 14px;
   border-bottom: 1px solid #f2f2f3;

   &:last-child {
      border-bottom: none;
   }

   &:hover {
      color: $blue;
   }
}

.categories__dropdownItem--active {
   color: $lightBlue;
   font-size: 16px;
   z-index: 10;
   position: relative;
   padding: 5px 0;
   width: fit-content !important;
}

// technology

.section-technology {
   @extend .section;
   margin-top: 100px;
   padding-top: 44px;
   max-width: 1150px;
}

.technologies {
   display: grid;
   grid-template-columns: repeat(auto-fill, 110px);
   justify-content: space-between;
   grid-gap: 35px;
}

.technology {
   background: #FFFFFF;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   align-items: center;

   img {
      height: 70px
   }
}

// book a call

.section-call {
   margin-top: 100px;
   border: 1px solid gray;
}

// form 

.form__section {
   margin-top: 70px;
   background: linear-gradient(360deg, #E0E9F9 10.5%, rgba(242, 244, 249, 0) 100%);

}

.form__wrapper {
   @extend .section;
}

.form {
   width: 100%;
   max-width: 450px;
   color: #fff;
   padding: 30px;
   flex: 1;

   background: linear-gradient(154.72deg, #F2F4F9 -71.98%, #344E78 117.64%);
   border-radius: 6px;

   &__input {
      margin-top: 3px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      padding: 8px 16px;
      flex: 1;
      font-size: 16px;
      outline: none;
      transition: $transition;
      border: 1px solid rgba(255, 255, 255, 0.15);

      &:focus {
         border: 1px solid rgba(255, 255, 255, 0.4);
      }

      &::placeholder {
         color: rgba(255, 255, 255, 0.655);
         font-size: 14px;
      }

   }

   &__textarea {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 20px;
      padding: 10px 16px;
      width: 100%;
      font-size: 16px;
      line-height: 16px;
      margin-top: 3px;
      outline: none;
      resize: none;

      &:focus {
         border: 1px solid rgba(255, 255, 255, 0.4);
      }

      &::placeholder {
         color: rgba(255, 255, 255, 0.655);
         font-size: 14px;
      }
   }

   &__btn {
      border: 1px solid #fff;
      font-weight: 500;
      padding: 11px 33px;
      margin:  0 auto;
      margin-top: 30px;
      width: fit-content;
      cursor: pointer;
      transition: $transition;
      border-radius: 24px;
      
      &:hover {
         background: rgb(255, 255, 255);
         color: $blue;
      }
   }
}


// media 

@media (max-width: 1024px) {
   .bannerIllustration--tablet {
      display: block;
      position: absolute;
      top: 300px;
      right: 0;
      width: 55%;
   }
}

@media (max-width: 1070px) {
   .portfolio {
      &__menuItem {
         font-size: 14px;
      }
   }

   .banner {
      margin-top: 130px;

      &__slogan {
         font-size: 54px;
         width: 450px;
      }

      &__illustration {
         width: 550px;
      }
   }
}

@media (max-width: 980px) {
   .portfolio {
      &__projects {
         column-count: 2;
         margin-left: -0px;
         width: 100%;
      }
   }
   
 }

 @media (max-width: 767px) {
   .banner {
      &__slogan {
         font-size: 46px;
         line-height: 40px;
         width: auto;
      }
   }
   
   // form 

   .form__section {
      margin-top: 80px;
   }
   
   .form__wrapper {
      width: 100%;
   }

   .form {
      max-width: 100%;
      border-radius: 0;
      padding: 30px 20px 70px 20px;

      &__btn {
         width: 100%;
         text-align: center;
      }
   }
   
   .section-services, .section-feedback, .section-portfolio, .section-technology, .section-call {
      margin-top: 50px;
   }
   
   .portfolio {
      &__projects {
         column-count: 1;
      }
   }

   // feedback 

   .feedback {
      &__slider {
         margin: 20px 0;
         height: 200px;
      }

      &__arrows {
         top: -50px;
      }

      &__prevArrow {
         left: 230px;
         
      }
      
      &__nextArrow {
         left: 280px;
      }
   }
 }


 @media (max-width: 640px) {

   .section-services, .section-feedback, .section-portfolio, .section-technology, .section-call {
      margin-top: 30px;
   }

   .banner {
      &__slogan {
         font-size: 32px;
         width: auto;
         text-align: center;
      }
   }

   .skills {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
      grid-gap: 20px;
      
   }

   .skill {
      width: 100%;
      height: 180px;
      margin: 0;
      padding: 20px;
   }

   .categories__dropdownItem--active {
      width: 95%;
   }

   .portfolio {
      &__projectBox {
         height: 330px;
      }

      &__projectBox--bigger { 
         height: 330px;
         min-height: 330px;
      }
   }

   .technologies {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-gap: 20px;
   }
   
   .technology {
      height: 100px;
      padding: 10px;
      justify-content: space-between;
   }
}

@media (max-width: 520px) { 

   .bannerIllustration--phone {
      display: block;
      position: absolute;
      top: 380px;
      right: 0;
   }
   .bannerIllustration--tablet {
      display: none;
   }
}