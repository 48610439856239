@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.parking {
    font-family: "Inter", sans-serif;
    color: #333333;

    &__section {
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
        overflow: hidden;
    }

    &__textLight {
      color: #777586;
      font-weight: 300; 
      font-size: 14px; 
    }

    &__subtitle {
        @extend .parking__textLight;
        max-width: 400px;
        font-size: 16px;
    }
}