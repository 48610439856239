.work__section {
   width: 90%;
   max-width: 1250px;
   margin: 0 auto;
   overflow: hidden;
}

.absoluteSection {
   @extend .work__section;
   position: absolute;
   top: 0;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.filter_map {
   filter: brightness(0.60);
}

.section {
   width: 90%;
   max-width: 1250px;
   margin: 0 auto;
}
