$black: #2A2932;
$blue: #547FC4;
$lightBlue: #69A0F7;
$transition: .3s ease-in-out;


.section-menu-wrapper {
   position: absolute;
   width: 100%;
   background: #fff;
   z-index: 30;
}
.section-menu-wrapper-shadow {
   box-shadow: 2px 2px 2px 0px #24242D0A;
   position: absolute;
   width: 100%;
   background: #fff;
   z-index: 30;
}

.section-menu {
   width: 90%;
   max-width: 1250px;
   z-index: 20;
}

.menuItem {
   font-size: 13px;
   color: $black;
   margin-right: 40px;
   padding: 30px 0;
   border-top: 3px solid transparent;
   cursor: pointer;
   transition: $transition;
   text-transform: uppercase;

   &:hover {
      color: $blue;
   }

   &:last-child {
      margin-right: 0;
   }
}

.menuItem--active {
   @extend .menuItem;
   border-top: 3px solid $blue;
   font-weight: 500;
}


.menuItemLight {
   @extend .menuItem;
   color: #b7bbbc;

   &:hover {
      color: #ffffff;
   }
}

.menuItem--activeLight {
   @extend .menuItemLight;
   border-top: 3px solid #fff;
   font-weight: 500;
   color: #fff;
}

.menuItem--activeBorder {
   @extend .menuItem--active;
   border-top: 3px solid #fff;
}

// phone menu

.burger {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 90%;
}

.burgerLight {
   @extend .burger;
   color: #fff;
}

.burgerIcon {
   position: absolute;
   z-index: 100;
   top: 15px;
   right: 0;
}

.burger--active {
   @extend .burger;
   position: fixed;
}

.burger--activeLight {
   @extend .burgerLight;
   position: fixed;
   color: #2A2932;
}

.phoneMenu__wrapper {
   backdrop-filter: blur(4px);
   position: fixed;
   top: 0;
   right: 0;
   height: 100vh;
   width: 100%;
   z-index: 20;
}

.phoneMenu {
   width: 80%;
   height: 100%;
   background: #fff;
   position: absolute;
   right: 0;
   top: 0;
   box-shadow: -4px 0px 12px rgba(42, 41, 50, 0.12);

   &__itemPortfolio {
      background: linear-gradient(90.14deg, #BA7ECF 2.9%, #F19ED0 23.1%, #948DF2 44.99%, #B9C2FF 75.22%, #FBCEA5 101.86%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600 !important;
      background-clip: text;
      text-fill-color: transparent;
   }

   &__item {
      font-size: 16px;
      margin: 16px 0;
      text-transform: uppercase;
      text-align: center;
      font-weight: 500;
      letter-spacing: 1px;
   }

   &__requestBtn {
      cursor: pointer;
      padding: 12px 20px;
      color: #fff;
      margin-top: 50px;
      width: 100%;
      text-transform: uppercase;
      border-radius: 6px;
      background-image: linear-gradient(to right, #FD8C1D 0%, #fb6b1c 51%, #FD8C1D 100%);
   }
}

.menu {
   &__bookCallBtn {
      border: 1px solid $lightBlue;
      padding: 10px 28px;
      font-size: 14px;
      display: flex;
      color: $lightBlue;
      border-radius: 30px;
      font-weight: 600;
      margin-left: 10px;
   }

   &__bookCallBtn--white {
      @extend .menu__bookCallBtn;
      background: rgba(255, 255, 255, 0.489);
   }
}

.menuBottomShadow {
   box-shadow: 2px 2px 2px rgba(36, 36, 45, 0.04);
   height: 82px;
}

@media (max-width: 1200px) {
   .menuItem {
      margin-right: 20px;
      font-size: 12px;
   }
}


@media (max-width: 1024px) {
   .menuItem {
      margin-right: 16px;
   }

   .section-menu-wrapper-shadow {
      box-shadow: none;
   }

   .menu {
      &__bookCallBtn {
         width: 125px;
         padding: 10px 15px !important;
      }
   }
}