.otherProjects {
   display: grid;
   grid-template-columns:  repeat(auto-fill, minmax(320px, 1fr));
   grid-gap: 30px;
   flex-wrap: wrap;
   margin-top: 20px;
   
   &__section {
      width: 90%;
      max-width: 1250px;
      margin: 0 auto;
      overflow: hidden;
   }
   
   &__box {
      height: 350px;
      border-radius: 12px;
      padding: 24px;
      cursor: pointer;
      overflow: hidden;
      transition: .3s ease-in-out;
       -webkit-mask-image: -webkit-radial-gradient(white, black);

      &:hover .otherProjects__arrow {
         opacity: 1;
         margin-right: 0;
      }
   }

   &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
   }

   &__desc {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 24px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
   }

   &__arrow {
      margin-left: 10px;
      opacity: 0;
      transition: .3s ease-in-out;
      margin-right: 5px;
   }
}


@media (max-width: 768px) {
   .otherProjects {
      grid-template-columns:  repeat(auto-fill, minmax(230px, 1fr));
      grid-gap: 20px;
      
      &__box {
         height: 320px;
      }
   }
}