.siteForPrint {
    &__section {
        font-family: 'Gilroy';
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
    }

    &__sectionNotCentered {
        font-family: 'Gilroy';
        width: 90%;
        max-width: 1250px;
        overflow: hidden;
        margin-left: auto;
    }

    &__about {
        background: linear-gradient(188.22deg, #E8F3FF 30.74%, rgba(205, 231, 255, 0) 93.69%);
        border-radius: 40px 40px 0 0;
        padding: 100px;
    }

    &__plainText {
        max-width: 410px;
        font-size: 16px;
    }

    &__aboutTagsSection {
        background: #fff;
        border-radius: 40px 40px 0 0;
        padding: 100px;
        align-items: stretch;
    }

    &__aboutTtitle {
        color: #4EA9FD;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__aboutTagSectionWrapper {
        border-top: 1px solid #C0E1FF;
        border-radius: 30px 30px 0 0;
        height: 100%;

    }

    &__aboutTagSection {
        padding-top: 100px;
        height: 100%;
        position: relative;

        img {
            position: absolute;
            top: 50px;
            left: -1px;
        }

    }

    &__aboutTag {
        background: #849BF2;
        padding: 18px 30px;
        color: #fff;
        font-size: 14px;
        border-radius: 40px;
        width: fit-content;
        flex: none;
        text-align: center;
        height: fit-content;
        z-index: 10;

        &--wide {
            width: 100%;
        }
    }

    &__colorBase {
        position: relative;
        background: #fff;
        box-shadow: 1.64px 1.64px 6.55px 0px #0000001F;
        border-radius: 19px;
        padding: 10px 35px;
        font-size: 24px;
    }

    &__colorSample {
        border-radius: 100%;
        height: 44px;
        width: 44px;
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__colorSample--blue {
        background: #4EA9FD;
    }

    &__colorSample--dark {
        background: #333333;
    }

    &__colorSample--lightBlue {
        background: #F1F8FF;
    }

    &__colorSample--lightBlue2 {
        background: #D2E9FF;
    }

    &__colorSample--lightGray {
        background: #929090;
    }

    &__blueText {
        color: #4EA9FD;
    }

    &__darkText {
        color: #333333;
    }

}


@media (max-width: 1280px) {
    .siteForPrint {
        &__about {
            border-radius: 20px 20px 0 0;
            padding: 50px;
        }

    &__sectionNotCentered {
        margin: 0 auto;
    }

    }
}

@media (max-width: 1070px) {
    .siteForPrint {

    &__aboutTagsSection {
        padding: 30px;
    }
}
}

@media (max-width: 768px) {
    .siteForPrint {
        &__plainText {
            max-width: 100%;
        }
        &__about {
            padding: 20px;
        }

        &__aboutTagsSection {
            border-radius: 20px 30px 0 0;
            flex-direction: column;
            padding: 20px;
        }

        &__aboutTagSectionWrapper {
            border: none;

            img {
                display: none;
            }
        }

        &__aboutTagSection {
            padding-top: 10px;
        }

        &__aboutTag {
            flex: 1;
        padding: 10px 15px;
        }

        &__colorBase {
            padding: 10px 25px;
            font-size: 16px;
        }
    
        &__colorSample {
            height: 30px;
            width: 30px;
            top: -25px;
        }
    }

}

// @media (max-width: 768px) {
//     .siteForPrint {
    
//     }
// }
