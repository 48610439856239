.dog__bannerSection {
   position: absolute;
   width: 90%;
   max-width: 1250px;
   margin: 0 auto;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   overflow: visible;
}

@media (max-width: 640px) {
   .dog__bannerSection {
      top: 40%;
   }
}