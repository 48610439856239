@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth
}

/* for slider */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&family=Montserrat:wght@500;600&display=swap');

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
      url('./fonts/Gilroy-Bold.woff2') format('woff2'),
      url('./fonts/Gilroy-Bold.woff') format('woff'),
      url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
      url('./fonts/Gilroy-Semibold.woff2') format('woff2'),
      url('./fonts/Gilroy-Semibold.woff') format('woff'),
      url('./fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
      url('./fonts/Gilroy-Medium.woff2') format('woff2'),
      url('./fonts/Gilroy-Medium.woff') format('woff'),
      url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
      url('./fonts/Gilroy-Regular.woff2') format('woff2'),
      url('./fonts/Gilroy-Regular.woff') format('woff'),
      url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'),
      url('./fonts/Gilroy-Light.woff2') format('woff2'),
      url('./fonts/Gilroy-Light.woff') format('woff'),
      url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Avenir */
@font-face {
  font-family: 'Avenir';
  src: local('Avenir Regular'), local('Gilroy-Regular'),
    url('./fonts/Avenir/avenir_roman_12.woff') format('woff'),
}