.gasTrainer {
    &__section {
        font-family: 'Gilroy';
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
        overflow: hidden;
    }

    &__bannerText {
        max-width: 410px;
        font-size: 16px;
    }

    &__gradientSection {
        background: #2F66AB;
        background: linear-gradient(155.84deg, #2F66AB -15.74%, #5F9AE4 95.34%);
        border-radius: 86px;
    }

    &__line {
        height: 100px;
        width: 2px;
        background: #BEC9D7;
        margin: 0 auto;
    }

    &__circleText {
        color: #3971B7;
        border: 2px solid #BEC9D7;
        padding: 40px;
        width: 290px;
        height: 290px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 22px;
        margin-left: -12px;
        margin-right: -12px;
    }
}


@media (max-width: 1262px) {
    .gasTrainer {
        &__circleText {
        width: 230px;
        height: 230px;
        font-size: 16px;
        }
    }
}

@media (max-width: 820px) {
    .gasTrainer {
        &__circleText {
        width: 180px;
        height: 180px;
        font-size: 16px;
        }
    }
}

@media (max-width: 768px) {
    .gasTrainer {
        &__bannerText {
            max-width: 100%;
            font-size: 14px;
        }

        &__gradientSection {
            border-radius: 20px;
        }

        &__circleText {
            width: 200px;
            height: 200px;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: -12px;
            margin-bottom: -12px;
        font-size: 13px;

        }

        &__line {
            margin-top: 40px;
        }
    }
}