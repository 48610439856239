.formOp {
    &__section {
        font-family: 'Gilroy';
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
        overflow: hidden;
    }

    &__gradientBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(130.43deg, #417BC3 1.21%, #2B60A0 106.44%);
        background: #417BC3;
        height: 350px;
        flex: 1;
        border-radius: 40px;
        padding: 30px 20px;
    }

    &__gradientSection {
        background: #417BC3;
        background: linear-gradient(259.22deg, #417BC3 -4.18%, #588BCA 78.59%);
        border-radius: 40px;
    }
}

@media (max-width: 768px) {
    .formOp {
        &__gradientBlock {
            height: 300px;
            border-radius: 20px;
        }

        &__gradientSection {
            border-radius: 20px;
        }
    }
}