#pinkShadow {
   animation: pinkShadow 5s ease-in-out infinite alternate;
}

#yellowShadow {
   animation: yellowShadow 5s ease-in-out infinite alternate;
}
  
#purpleShadow {
   animation: purpleShadow 8s ease-in-out infinite alternate;
}


// animations 

@keyframes pinkShadow {
   0% {
     transform: translateX(-10%) translateY(-10%);
   } 

   50% {
     transform: translateX(10%) translateY(20%);
   }

   100% {
     transform: translateX(20%) translateY(30%);
   }
}

@keyframes yellowShadow {
   0% {
     transform: translateX(0%) translateY(-10%);
   } 

   50% {
     transform: translateX(20%) translateY(-40%);
   }

   100% {
     transform: translateX(10%) translateY(10%);
   }
}

@keyframes purpleShadow {
   0% {
     transform: translateX(0%) translateY(-10%);
   } 

   50% {
     transform: translateX(20%) translateY(-40%);
   }

   100% {
     transform: translateX(10%) translateY(10%);
   }
}