.selectra {
    font-family: 'Gilroy';

    &__section {
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
        overflow: hidden;
    }

    &__bannerImgWrapper {
        height: max-content;
        width: 100%;
    }

    &__colorBlue1 {
        background: #417BC3;
    }

    &__colorBlue2 {
        background: #2B61A5;
    }

    &__colorYellow {
        background: #E9A744;
    }

    &__colorLightBlue {
        background: #D1DFF1;
    }

    &__colorGray {
        background: #F8F8F8;
    }

    &__blueText {
        color: #2B61A5;
    }

    &__gradientBg {
        background: #2B61A5;
        background: linear-gradient(130.6deg, #2B61A5 -27.39%, #568CD0 73.08%);
        height: 380px;
        width: 100%;
        z-index: -1;
    }

    &__plainText {
        max-width: 570px;
        font-size: 16px;
        color: #3C3C3C;
    }

    &__screensSection {
        overflow: hidden;
        padding-left: 50px;

        img {
            height: 320px;
        }
    }
}


@media (max-width: 1285px) {
    .selectra {
        &__bannerImgWrapper {
            img {
                object-fit: cover;
                height: 600px;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1024px) {
    .selectra {
        &__plainText {
            max-width: 300px;
            width: 100%;
        }


    }
}



@media (max-width: 765px) {
    .selectra {
        &__screensSection {

            width: 90%;
            max-width: 1250px;
            margin: 0 auto;
            padding-left: 0;

            div {
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;
            }
        }
    }
}

@media (max-width: 640px) {
    .selectra {
        &__plainText {
            max-width: 100%;
            width: 100%;
            font-size: 14px;
            text-align: center;
        }

        &__bannerImgWrapper {
            img {
                object-fit: cover;
                height: 500px;
                width: 100%;
            }
        }
    }
}