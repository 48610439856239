#eye, #eye_2  {
   -webkit-transform-origin: 50%;
    -webkit-animation: blink 3.5s ease-in-out 1s infinite;
  }

  @keyframes blink {
   0%, 100% {
       transform: scale(1, .05);
   }
   5%,
   95% {
       transform: scale(1, 1);
   }
}