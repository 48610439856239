.tickets{
   &__phoneBg {
      width: 100%;
      height: 380px;
      object-fit: cover;
   }

   &__bannerOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 380px;
      background: rgba(255, 255, 255, 0.227);
      backdrop-filter: blur(4px);
   }
   
   &__typography {
      font-family: 'Montserrat', sans-serif;
   }
}