@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.rw {

   font-family: "Prompt", serif;

   .columns-3 {
      column-count: 3;
      column-gap: 2rem;
   }

   .columns-2 {
      column-count: 2;
      column-gap: 2rem;
   }

   &__container {
      width: 85%;
      max-width: 2000px;
      margin: 0 auto;
   }

   &__radius {
      border-radius: 100px;
   }

   &__headerBgWrapper {
      width: 100%;
      overflow: hidden;
   }

   &__section {
      width: 90%;
      max-width: 1250px;
      margin: 0 auto;
      overflow: hidden;
   }

   &__banner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 70px 20px;
   }

   &__thingsSection {
      flex: 2;
      flex-wrap: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;
   }

   &__browserSection--bg {
      overflow: hidden;
      background-image: linear-gradient(to right top, #fdf3f8, #f7ecf5, #eee5f3, #e4dff2, #d7daf0);
   }

   &__browserSection--text {
      flex: 1;
      min-width: 300px;
   }

   &__colorsSectionIcons {
      display: flex;
      width: 100%;
      max-width: 566px;
      flex-wrap: wrap;
   }
}


@media (max-width: 1024px) {
   .rw {
      &__colorsSectionIcons {
         max-width:100%;
      }
      &__radius {
         border-radius: 50px;
      }
      &__container {
         width: 90%;
      }
   }
}

@media (max-width: 640px) {
   .rw {
      &__radius {
         border-radius: 30px;
      }
      &__banner {
         padding: 40px 20px;
      }
   }
}